<template>
<div class="menu">
    <a-modal 
    :title="title" 
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    v-model:visible="showDialog">
     <m-dialog-content  
                :readOnly="false"
                :formItems="popForms">
                </m-dialog-content>
    </a-modal>
    <div class="tree_data" v-if="false">
         <a-tree :tree-data="treeData"/>
    </div>
    <div class="menu_edit">
        <div class="menu_edit_tree">
            <a-tree-select
                v-model:value="value"
                show-search
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="Please select"
                allow-clear
                tree-default-expand-all
                @select="getSelect"
                >
                <a-tree-select-node 
                v-for="nav in treeData" 
                :key="nav.key" 
                :parentMenuId="nav.parentMenuId"
                :value="nav.menuId" 
                :title="nav.menuName">
                    <template v-if="nav.childMenus && nav.childMenus.length > 0">
                        <a-tree-select-node 
                        v-for="subNav in nav.childMenus" 
                        :key="subNav.key" 
                        :value="subNav.key" 
                        :parentMenuId="subNav.parentMenuId"
                        @select="getSelect"
                        :title="subNav.menuName">
                             <a-tree-select-node 
                                v-for="subNavC in subNav.childMenus" 
                                :key="subNavC.key" 
                                :value="subNavC.key" 
                                :parentMenuId="subNavC.parentMenuId"
                                @select="getSelect"
                                :title="subNavC.menuName">    
                                </a-tree-select-node>
                        </a-tree-select-node>
                    </template>
                </a-tree-select-node>
            </a-tree-select>
        </div>
        <div class="menu_edit_buttons">
            <button 
            @click="handleEvent(button.eventName)"
            v-for="button in handleButtons" 
            :key="button.eventName">
                {{button.text}}
            </button>
        </div>
    </div>
</div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
// import { menus } from '../../common/common-data'
// import { MENU, EditMenuDto } from '../../utils/model'
import {  getMenuItem, getMenuTree, deleteMenu, addMenu, editMenu, isValid} from '../../utils/api'
import { formatMenuTree } from '../../utils/util'
import { Modal } from 'ant-design-vue'
// import { routes } from '../../router/route'
import { message } from 'ant-design-vue'

// interface Nav {
//     navs: MENU[];
// }
export default {
    setup() {
        const state = reactive({
            handleButtons: [
                {text: '编辑菜单栏', eventName: 'edit'},
                {text: '删除菜单栏', eventName: 'delete'},
                {text: '添加菜单栏', eventName: 'add'},
                // {text: '获取菜单数据', eventName: 'getMenu'}
            ],
            // navs: [],
            addForms: [
                {label: '父菜单ID', value: '1', type: 'input', prop: 'menuId'},
                {label: '菜单名', value: '', type: 'input', prop: ''}
            ],
            editForms: [
                {label: '父菜单ID', value: '', type: 'input', prop: 'parentMenuId'},
                {label: '菜单名', value: '', type: 'input', prop: 'menuName'},
                {label: '菜单ID', value: '', type: 'input', prop: 'menuId'},
                {label: '排序等级', value: '', type: 'input', prop: 'sortNum'},
            ],
            menuItem: {},
            value: [],
            treeExpandedKeys: [],
            title: '编辑',
            showDialog: false,
            confirmLoading: false,
            eventName: '',
            validValue: {menuName: '', menuId: ''},
            checked: false, // 是否添加统计菜单
            rootMenuID: 1,
            treeData: [],
            dialogType: 'add',
            selectedMenuID: '',
        })
        const popForms = computed(() => {
            return state.dialogType == 'add' ? state.addForms : state.editForms
        })
        // 获取菜单树
        async function loadData() {
            try {
                const result = await getMenuTree()
                if (isValid(result)) {
                    state.treeData = formatMenuTree(result.data.childMenus)
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        // formatMenuTree(state.treeData)
        // const _navs: Nav = reactive({
        //     navs: []
        // })
        // const isAdd = computed(() => {
        //     return state.eventName == 'add'
        // })
        // let selectedValues: EditMenuDto
        // let treeArray: MENU[]
        // async function getMenu() {
        //     try {
        //         const getMenus = menus()
        //         if(getMenus.menuInfo.menues.length < 1) {
        //             await getMenus.getMenus()
        //         }
        //         _navs.navs = getMenus.menuInfo.menues && getMenus.menuInfo.menues
        //         treeArray = _navs.navs
        //     } catch(e) {
        //         console.log(e)
        //     }
        // }
        // getMenu()
        // 数组转为对象
        function tranArrayToObject(arr) {
            const obj = {}
            arr.forEach(ele => {
                obj[ele.prop] = ele.value
            })
            return obj
        }
        // 编辑菜单
        async function edit() {
            try {
                const params = tranArrayToObject(state.editForms)
                console.log(params)
                const result = await editMenu(params)
                if (result.code == 0) {
                    message.success('编辑菜单成功')
                    loadData()
                }
            } catch(e) {
                console.error(e)
            }
        } 
        // 删除菜单
        async function deleteMenus() {
            try {
                    Modal.confirm({
                        title: '删除', 
                        content: '删除不可恢复，是否确认选中菜单？',
                        icon: '',
                        async onOk () {
                            const data = {
                                menuId: state.menuItem.menuId
                            }
                            const result = await deleteMenu(data)
                            if(result.code == 0) {
                                message.success('删除成功')
                                loadData()
                            }
                        }
                    })    
            } catch(e) {
                console.error(e)
            }
        }
        // 添加菜单
        async function add() {
            try {
                const items = state.addForms
                const params = {
                    parentMenuId: items[0].value,
                    menuName: items[1].value,
                    sortNum: 1
                }
                const result = await addMenu(params)
                if(result.code == 0) {
                    message.success('添加成功')
                    loadData()
                }
            } catch(e) {
                console.error(e)
            }
        }
        function handleEvent(eventName) {
            if (eventName == 'add') {
                state.addForms.forEach(ele => {
                    ele.value = state.menuItem[ele.prop]
                })
                state.dialogType = 'add'
                state.showDialog = true
            } else if (eventName == 'edit') {
                state.editForms.forEach(ele => {
                    ele.value = state.menuItem[ele.prop]
                })
                state.dialogType = 'edit'
                state.showDialog = true
            }else if(eventName == 'delete') {
                deleteMenus()
            } 
            // else if (eventName == 'getMenu') {
            //     getMenu()
            // } else {
            //     state.validValue.menuName = ''
            //     state.showDialog = true
            // }
            state.eventName = eventName
        }
        // function findMenu(menuId: number) {
        //     for(let i = 0; i < treeArray.length; i++) {
        //         if(treeArray[i].menuId == menuId) {
        //             selectedValues = treeArray[i]
        //             // state.validValue = treeArray[i]
        //             break
        //         }
        //     }
        // }
        async function getSelect(value) {
            try {
                const result = await getMenuItem(value)
                if (isValid(result)) {
                    state.menuItem = result.data
                }
            } catch(e) {
                console.error(e)
            }
            // const menuId = value.split(',')[1]
            // findMenu(menuId)
            // state.validValue = value
            // state.selectedMenuID = value
            // console.log(value)
        }
        function handleOk() {
            switch(state.eventName) {
                case 'edit':
                    state.title = '编辑' 
                    edit()
                    break;
                case 'delete':
                    deleteMenus()
                    break;
                case 'add':
                    state.title = '添加'
                    add()
                    break
            }
            // getMenu()
            state.showDialog = false
        }
        // function onChange(e: any) {
        //     state.checked = e.target.value == '1'
        // }
        return {
            ...toRefs(state),
            getSelect,
            handleEvent,
            handleOk,
            popForms,

            // isAdd,
            // onChange,
            // getMenu,
            // ...toRefs(_navs)
        }
    }
}
</script>
<style lang="scss" scoped>
.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &_edit {
        background: #fff;
        padding: 30px;
        border-radius: 5px;
        width: 400px;
        &_buttons {
            margin: 20px 0;
            button {
                margin: 10px;
            }
        }
    }
}
.dialog_item {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
        div {
            width: 300px;
        }
    }
</style>